export const priceList = [
    {
        title: "Business card website",
        price: `80$`,
        description: 'Small website consisting of one or more pages and containing basic information.'
    },
    {
        title: "Landing Page",
        price: "from 100$",
        description: 'Landing Page — a web page whose main task is to collect contact information of the target audience.'
    },
    {
        title: "Corporate website",
        price: "from 300$",
        description: 'This is an interface for employees to access the knowledge necessary to work in the company.'
    },
    {
        title: "Online store",
        price: "from 350$",
        description: 'This is an interface for employees to access the knowledge necessary to work in the company.'
    },
    {
        title: "Promotion",
        price: "from 70$/М",
        description: 'A complete and correct set up your website for search aggregates such as Yandex, Google.'
    },
    {
        title: "Technical support and maintenance",
        price: "from 25$/М",
        description: 'We are ready to offer you a set of measures related to ensuring the stable operation of your website.'
    },
];