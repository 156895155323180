import { useState, useEffect } from 'react';
import logo from "../../assets/img/logo.svg";
import styles from "../../assets/style/header.module.scss";
import { menu } from "../../data/header.module.js";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuVisible, setMenu] = useState(false)

  useEffect(() => {
    if (menuVisible){
      document.querySelector('nav').classList.remove(styles.mobHidden)
      document.querySelector('nav').classList.add(styles.mobShow)
    } else {
      document.querySelector('nav').classList.add(styles.mobHidden)
      document.querySelector('nav').classList.remove(styles.mobShow)
    }
  })

  return (
    <header className={styles.wrapper}>
      <div className={styles.inner}>
        <Link className={styles.logo} to="/">
          <img src={logo} alt="Samara.fun" className={styles.logo} onClick={() => setMenu(false)} />
        </Link>
        <nav className={styles.menu}>
          {menu.map((item, id) => (
            <Link key={`menu item ${id}`} to={item.link} onClick={() => setMenu(false)} className={styles.link}><i className={item.ico}></i>{item.name}</Link>
          ))}
        </nav>
        <button className={styles.button} onClick={() => setMenu(true)}>
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>
    </header>
  );
};

export default Header;
