import styles from '../assets/style/home.module.scss'
import { Link } from "react-router-dom";
import { useEffect } from 'react';

const Home = () => {

  useEffect(() => {
    document.title = 'Samara.FUN | Home';
  }, [])
  
  return (
    
    <section className={styles.wrapper}>
        <h1 className={`${styles.title} animate__animated animate__fadeInUp`}>Creating websites for you</h1>
        <h3 className={`${styles.description} animate__animated animate__fadeInUp`}>We are engaged in the creation of websites and their further promotion</h3>
        <Link className={`${styles.link} animate__animated animate__fadeInUp`} to="/working">
          <i className="fa-solid fa-angle-right"></i> More detailed
        </Link>
    </section>
  );
}

export default Home;