import { useEffect } from 'react';
import styles from '../assets/style/comments.module.scss'
import avatarOne from "../assets/img/avatar.jpg";
import avatarTwo from "../assets/img/avatar2.jpg";
import avatarFree from "../assets/img/avatar3.jpg";

const Comments = () => {

   useEffect(() => {
      document.title = 'Samara.FUN | Reviews';
    }, []);

   return (
      <section className={styles.comment}>
         <div className={styles.comment__inner}>
            <h2 className={`${styles.comment__title} animate__animated animate__fadeIn`}>Customer reviews</h2>
            <div className={`${styles.comment__description} animate__animated animate__fadeIn`}>We value our customers very much and we are reciprocated!</div>
            <div className={styles.comment__items}>
               <div className={`${styles.comment__item} animate__animated animate__fadeIn`}>
                  <div className={styles['comment__item-inner']}>
                     <img className={styles['comment__item-avatar']} src={avatarOne} alt="" />
                     <div className={styles['comment__item-info']}>
                        <div className={styles['comment__item-title']}>Людмила В.А</div>
                        <a className={styles['comment__item-site']} href="/"><i className="far fa-clone"></i> SAMARA.FUN</a>
                     </div>
                  </div>
                  <div className={styles.comment__rating}>
                     <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i
                        className="fas fa-star"></i> <i className="fas fa-star"></i>
                  </div>
                  <p className={styles['comment__item-text']}>I want to express my great gratitude to your team and personally to Bogdan for
                     individual approach and implementation of the set goals...</p>
               </div>
               <div className={`${styles.comment__item} animate__animated animate__fadeIn`}>
                  <div className={styles['comment__item-inner']}>
                     <img className={styles['comment__item-avatar']} src={avatarTwo} alt="" />
                     <div className={styles['comment__item-info']}>
                        <div className={styles['comment__item-title']}>Сергей К.П</div>
                        <a className={styles['comment__item-site']} href="/"><i className="far fa-clone"></i> SAMARA.FUN</a>
                     </div>
                  </div>
                  <div className={styles.comment__rating}>
                     <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i
                        className="fas fa-star"></i> <i className="fas fa-star"></i>
                  </div>
                  <p className={styles['comment__item-text']}>Personally, I don't really understand anything in this matter, so we trusted SAMARA.FUN,
                     I immediately liked their approach and creativity. They made a great modern website for us...</p>
               </div>
               <div className={`${styles.comment__item} animate__animated animate__fadeIn`}>
                  <div className={styles['comment__item-inner']}>
                     <img className={styles['comment__item-avatar']} src={avatarFree} alt="" />
                     <div className={styles['comment__item-info']}>
                        <div className={styles['comment__item-title']}>Анна К.Т</div>
                        <a className={styles['comment__item-site']} href="/"><i className="far fa-clone"></i> SAMARA.FUN</a>
                     </div>
                  </div>
                  <div className={styles.comment__rating}>
                     <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i
                        className="fas fa-star"></i> <i className="fas fa-star"></i>
                  </div>
                  <p className={styles['comment__item-text']}>We have developed a website catalog of fasteners. Deadlines were burning, the site was needed
                     yesterday. We did it as soon as possible. We were satisfied with the work.</p>
               </div>
            </div>
            <a className={styles.comment__link} href="#comment"><i className="fas fa-arrow-circle-right"></i> More reviews</a>
         </div>
      </section>
   );
}

export default Comments;