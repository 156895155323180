import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route,} from "react-router-dom";

import Home from './page/Home'
import Price from './page/Price'
import Comments from './page/Comments'
import Contacts from './page/Contacts'
import Works from './page/Works'
import Working from './page/Working'
import Header from './components/Header/Header'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <Router>
    <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Price />} />
        <Route path="/comments" element={<Comments />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/works" element={<Works />} />
        <Route path="/working" element={<Working />} />
      </Routes>
    </Router>
  </div>
);
