import { useEffect } from 'react';
import styles from '../assets/style/working.module.scss'
import img1 from '../assets/img/how-1.svg'
import img2 from '../assets/img/how-2.svg'
import img3 from '../assets/img/how-3.svg'

const Working = () => {

   useEffect(() => {
      document.title = 'Samara.FUN | How we Work';
    }, []);

   return (
      <section className={styles.wrapper}>
         <div className={styles.inner}>
            <h2 className={`${styles.title} animate__animated animate__fadeIn`}>How we work</h2>
            <span className={`${styles.description} animate__animated animate__fadeIn`}>We describe the process of web development, as it happens in our company (and in
               most bona fide studios). In practice, individual items may be omitted or, conversely,
               something new is added.</span>
            <div className={styles.items}>
               <div className={`${styles.item} animate__animated animate__fadeIn`}>
                  <img className={styles.icon} alt="Бесплатная консультация" src={img1}/>
                  <div className={styles.subtitle}>Free consultation</div>
                  <div className={styles.subdescription}>At this preliminary stage, we consider it necessary for free
                     to advise you on the possibilities and prospects of the project.</div>
               </div>
               <div className={`${styles.item} animate__animated animate__fadeIn`}>
                  <img className={styles.icon} alt="Техническое задание" src={img2}/>
                  <div className={styles.subtitle}>Technical specification</div>
                  <div className={styles.subdescription}>Formation of a technical task for the creation of a website (TOR). It
                     it is compiled in a language understandable to specialists — web designers, programmers.</div>
               </div>
               <div className={`${styles.item} animate__animated animate__fadeIn`}>
                  <img className={styles.icon} alt="Дизайн и программирование" src={img3}/>
                  <div className={styles.subtitle}>Design and programming</div>
                  <div className={styles.subdescription}>At this preliminary stage, we consider it necessary for free
                     to advise you on the possibilities and prospects of the project.</div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default Working;