
import { useEffect } from 'react';
import styles from '../assets/style/contacts.module.scss'

const Contacts = () => {

    useEffect(() => {
      document.title = 'Samara.FUN | Contacts';
    }, []);

  return (
    <section className={styles.contacts}>
      <div className={styles.contacts__inner}>
        <div className={styles.contacts__items}>
          <div className={styles['contacts__item']}>
            <h3 className={styles['contacts__item-title']}>Форма связи:</h3>
            <span className={styles['contacts__item-description']}>Отправьте письмо на нашу почту, заполнив данные ниже и
               мы свяжемся с вами в ближайшее время.</span>
            <form method="POST" id="form" className={styles.contacts__form}>
              <div className={styles.contacts__inputs}>
                <div className={styles.contacts__subitem__inputs}>
                  <span className={styles['contacts__form-description']}>Ваше имя:</span>
                  <input name="name" id="name" type="text" placeholder="Введите имя..." required />
                </div>

                <div className={styles.contacts__subitem__inputs}>
                  <span className={styles['contacts__form-description']}>Ваш номер телефона:</span>
                  <input name="phone" id="phone" type="text" placeholder="Номер телефона..." required />
                </div>
              </div>
              <span className={styles['contacts__form-description']}>Опишите ваш заказ:</span>
              <textarea name="text" id="text" cols="30" rows="10" required></textarea>
              <button type="submit" className={styles['contacts__form-button']}>Отправить</button>
            </form>
            <span className={styles['contacts__item-description']}>Нажимая кнопку отправить в соглашаетесь с <a
              href="politic.html">политикой
              конфиденциальности.</a></span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contacts;