import { useEffect } from 'react';
import styles from '../assets/style/works.module.scss'
import img1 from '../assets/img/work1.png'
import img2 from '../assets/img/work2.png'
import img3 from '../assets/img/work3.png'
import img4 from '../assets/img/work4.png'
import img5 from '../assets/img/work5.png'
import img6 from '../assets/img/work6.png'

const Works = () => {

  useEffect(() => {
    document.title = 'Samara.FUN | Portfolio';
  }, []);

  return (
    <section className={styles.wrapper}>
      <div className={styles.inner}>
        <h2 className={`${styles.title} animate__animated animate__fadeIn`}>Our works</h2>
        <span className={`${styles.description} animate__animated animate__fadeIn`}>We have been creating websites since 2018, the accumulated knowledge and skills allow us to
          implement even the most complex projects. We approach the implementation of each project with full dedication!</span>
        <div className={styles.items}>
          <div className={`${styles.item} animate__animated animate__fadeIn`}>
            <img className={styles.picture} src={img1} alt=""/>
          </div>
          <div className={`${styles.item} animate__animated animate__fadeIn`}>
            <img className={styles.picture} src={img2} alt=""/>
          </div>
          <div className={`${styles.item} animate__animated animate__fadeIn`}>
            <img className={styles.picture} src={img3} alt=""/>
          </div>
          <div className={`${styles.item} animate__animated animate__fadeIn`}>
            <img className={styles.picture} src={img4} alt=""/>
          </div>
          <div className={`${styles.item} animate__animated animate__fadeIn`}>
            <img className={styles.picture} src={img5} alt=""/>
          </div>
          <div className={`${styles.item} animate__animated animate__fadeIn`}>
            <img className={styles.picture} src={img6} alt=""/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Works;