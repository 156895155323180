import { useEffect } from 'react';
import styles from '../assets/style/price.module.scss'
import { priceList } from "../data/price.module.js";

const Price = () => {
  
  useEffect(() => {
    document.title = 'Samara.FUN | Services and Prices';
  }, []);

  return (
    <section className={styles.price}>
      <div className={styles.price__inner}>
        <h2 className={`${styles.price__title} animate__animated animate__fadeIn`}>Services and prices</h2>
        <div className={`${styles.price__description} animate__animated animate__fadeIn`}>Our company strives to offer a product that will benefit our customers, and we also aim to provide the best price-quality ratio for our customers.</div>
        <div className={styles.price__items}>
          {priceList.map((item, id) => (
            <div key={`priceList item ${id}`} className={`${styles.price__item} animate__animated animate__fadeIn`}>
              <div className={styles.price__info}>
                <h3 className={styles['price__item-title']}>{item.title}</h3>
                <span className={styles['price__item-price']}>{item.price}</span>
                <div className={styles['price__item-description']}>{item.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section >
  );
}

export default Price;