export const menu = [
  {
    name: "How we work",
    link: "/working",
    ico: 'fa-solid fa-paint-roller',
    mobile: true
  },
  {
    name: "Services and prices",
    link: "/services",
    ico: 'fa-solid fa-layer-group',
    mobile: true
  },
  {
    name: "Portfolio",
    link: "/works",
    ico: 'fa-solid fa-briefcase',
    mobile: true
  },
  {
    name: "Reviews",
    link: "/comments",
    ico: 'fa-solid fa-thumbs-up',
    mobile: true
  }
];
